<template>
  <div class="dr-page">
    <div class="search-container">
      <el-date-picker class="date-select" v-model="searchTime" type="daterange" range-separator="至"
        start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <el-input class="saerch-text" size="medium" placeholder="请输入内容" prefix-icon="el-icon-search" v-model="searchText">
      </el-input>
    </div>
    <div class="line" />
    <div class="item" v-for="item of config.diary.diaryList" :key="item.id" v-show="(item[$i18n.locale].title.indexOf(searchText) != -1 ||
        item[$i18n.locale].subTitle.indexOf(searchText) != -1) &&
      (searchTime == null ||
        (searchTime[0].valueOf() <= new Date(item.time).valueOf() &&
          searchTime[1].valueOf() >= new Date(item.time).valueOf()))
      " @click="$router.push({
    name: 'diaryDetail',
    query: {
      ...item[$i18n.locale]
    }
  })">
      <p class="time">{{ item[$i18n.locale].time }}</p>
      <p class="title">{{ item[$i18n.locale].title }}</p>
      <p class="sub-title">{{ item[$i18n.locale].subTitle }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: "technology",
  data() {
    return {
      searchTime: null,
      searchText: "",
    };
  },
};
</script>
<style lang="scss" scoped>
.dr-page {
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 1024px;
  width: 100%;
  padding: 0 20px;

  .search-container {
    display: flex;
    ///  justify-content: space-between;
  }

  .date-select {
    width: 270px;
  }

  .saerch-text {
    margin-left: 20px;
    max-width: 400px;
    width: calc(100% - 290px);
  }

  .line {
    width: 100%;
    height: 2px;
    margin: 30px 0;
    background: #99979717;
  }

  .item {
    max-width: 700px;
    margin: 50px 0;
    cursor: pointer;
    font-size: 16px;

    .time,
    .sub-title {
      opacity: 0.5;
      font-size: 1em;
      margin: 0;
    }

    .title {
      font-size: 1.2em;
      margin: 0.5em 0;
    }
  }

  .item:hover,
  .item:active {
    opacity: 0.5;
  }
}

@media only screen and (max-width: 730px) {
  .dr-page {
    margin-top: 0px;

    .search-container {
      flex-direction: column;
    }

    .date-select {
      width: 100%;
    }

    .saerch-text {
      margin-left: 0;
      max-width: none;
      width: 100%;
      margin-top: 10px;
    }

    .line {
      margin: 20px 0;
    }
  }
}

@media only screen and (max-width: 550px) {
  .dr-page {
    .date-select {
      display: none;
    }

    .line+.item {
      margin-top: 30px;
    }

    .item {
      font-size: 3vw;
    }
  }
}
</style>

<style lang="scss">
.dr-page .el-date-editor .el-range-separator {
  color: #7575778a;
}

.dr-page .el-range-editor.is-active,
.dr-page .el-range-editor.is-active:hover,
.dr-page .el-select .el-input.is-focus .el-input__inner,
.dr-page .el-input__inner,
.dr-page .el-select .el-input.is-focus .el-input__inner,
.dr-page .el-select .el-input__inner:focus {
  border-radius: 4px;
  border: solid 1px #d4d4d4;
}

.dark .dr-page .el-range-editor.is-active,
.dark .dr-page .el-range-editor.is-active:hover,
.dark .dr-page .el-select .el-input.is-focus .el-input__inner,
.dark .dr-page .el-input__inner,
.dark .dr-page .el-select .el-input.is-focus .el-input__inner,
.dark .dr-page .el-select .el-input__inner:focus {
  border-radius: 4px;
  border: none;
  background: #262626;
}

.el-input--medium .el-input__inner {
  height: 40px;
  line-height: 40px;
}

.dr-page .el-range-editor .el-range-input,
.dr-page .el-input__prefix,
.dr-page .el-date-editor .el-range-separator,
.dr-page .el-input__prefix-inner {
  background: transparent;
}

.dr-page .el-input__icon {
  color: #c0c4cc;
  font-weight: bold;
  background: transparent;
}
</style>